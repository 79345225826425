export default `
mutation updateCustomer(
  $val: String!
){
  updateCustomer(input: {preferred_store: $val}) {
    customer {
      preferred_store
    }
  }
}
`