export default `
query customer {
  customer {
    backorder_allowed 
    use_product_feed
    preferred_store
    representive_id
    is_dropshipper
  }
}
`;
