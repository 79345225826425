import useApi from '~/composables/useApi';
import queryString from './queryString.gql';
import mutationStringUpdateSVGql from './mutationStringUpdateSV.gql';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useContext } from "@nuxtjs/composition-api"

const useUserCustomAtr = () => {
  const customerStore = useCustomerStore();
  const { app } = useContext()
  const customerToken = app.$cookies.get("vsf-customer")
  const { query, mutate } = useApi();
  const getData = async () => {
    if(!customerToken) return
    return query(queryString)
      .then((data) => {
        let parsed :any = data.data
        customerStore.userAdv = parsed?.customer ?? {};
        return parsed;
      })
      .catch((e) => {
        return e
      });
  }

  const updateCustomerPrefSV = (val) => {
    return mutate(mutationStringUpdateSVGql, {
      val: val
    })
    .then((data) => {
      return data;
    })
    .catch((e) => {
      return e.response
    });
  }
  return {
    getData,
    updateCustomerPrefSV
  }
}

export default useUserCustomAtr